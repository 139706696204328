<template>
	<nuxt-link
			v-if="to && !disabled"
			:to="to"
			class="button"
			:class="classes"
	>
		<ButtonInner
				:icon-left="iconLeft"
				:icon-right="iconRight"
				:text="text"
				:hide-text="hideText"
		/>
	</nuxt-link>
	<button
			v-else
			:type="type"
			class="button"
			:class="classes"
			:disabled="disabled"
	>
		<ButtonInner
				:icon-left="iconLeft"
				:icon-right="iconRight"
				:text="text"
				:hide-text="hideText"
		/>
	</button>
</template>

<script lang="ts" setup>
	import type {PropType} from 'vue';
	import {computed} from 'vue';
	import type {RouteLocationRaw} from 'vue-router';
	import ButtonInner from '~/components/generic/buttons/ButtonInner.vue';
	import type {ButtonClass, ButtonType} from '~/lib/types/ButtonTypes';
	import type {AvailableIcon} from '~/lib/types/IconTypes';

	const props = defineProps({
		type: {
			type: String as PropType<ButtonType>,
			default: 'button'
		},
		to: {
			type: [String, Object] as PropType<RouteLocationRaw>,
			default: null
		},
		text: {
			type: String,
			required: true
		},
		iconLeft: {
			type: String as PropType<AvailableIcon>,
			required: false,
			default: null
		},
		iconRight: {
			type: String as PropType<AvailableIcon>,
			required: false,
			default: null
		},
		btnClass: {
			type: String as PropType<ButtonClass>,
			default: 'primary'
		},
		hideText: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	});

	const classes = computed(() => {
		const classes: Array<string> = [props.btnClass];
		if (props.disabled) {
			classes.push('disabled');
		}
		return classes;
	});
</script>

<style scoped>
	.button {
		background: var(--button-bg);
		border: 1px solid var(--button-border-color);
		color: var(--button-color);
		border-radius: 5px;
		padding: 9px 15px;
		font-size: 0.8125rem;
		line-height: calc(20 / 13 * 1);
		display: inline-block;
		text-decoration: none;
		flex: 0 0 auto;
		transition: all .1s ease-in-out;
		max-width: 100%;
	}

	.button:not(:disabled) {
		cursor: pointer;
	}

	.button:disabled {
		opacity: .75;
	}

	.button:not(:disabled):hover {
		box-shadow: inset 0 0 0 1px var(--button-border-color);
	}

	.button:focus-visible {
		border-color: var(--button-bg);
		box-shadow: inset 0 0 0 1px #ffffff,
		inset 0 0 0 2px var(--button-border-color),
		0 0 0 2px var(--button-border-color);
		outline: none;
	}

	.button.minimal:active {
		box-shadow: inset 0 0 0 2px var(--button-border-color);
	}

	.button.primary {
		--button-bg: var(--color-primary-500);
		--button-border-color: var(--color-primary-500);
		--button-color: #ffffff;
	}

	.button.primary:not(:disabled):hover {
		--button-bg: var(--color-primary-600);
		--button-border-color: var(--color-primary-600);
	}

	.button.primary:active {
		--button-bg: var(--color-primary-700);
		--button-border-color: var(--color-primary-700);
	}

	.button.primary:focus-visible {
		--button-bg: var(--color-primary-500);
		--button-border-color: #ffffff;
		border-color: #ffffff;
		box-shadow: inset 0 0 0 1px #ffffff,
		0 0 0 2px var(--button-bg);
	}

	.button.minimal {
		--button-bg: #ffffff;
		--button-border-color: #ffffff;
		--button-color: var(--color-primary-500);
		font-weight: 700;
	}

	.button.secondary {
		--button-bg: #ffffff;
		--button-border-color: var(--color-primary-500);
		--button-color: var(--color-primary-500);
	}

	.button.minimal:not(:disabled):hover,
	.button.minimal:focus-visible,
	.button.secondary:not(:disabled):hover {
		--button-border-color: var(--color-primary-600);
		--button-color: var(--color-primary-600);
		--button-bg: var(--color-primary-50);
	}

	.button.minimal:active,
	.button.secondary:active {
		--button-border-color: var(--color-primary-700);
		--button-color: var(--color-primary-700);
		--button-bg: #ffffff;
	}
</style>
